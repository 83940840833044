import { numberOrNull } from 'utils/validation';
import { number, object, preprocess } from 'zod';

import { REQUIRED_MSG } from '../../../../../../../../constants';

const validation = object({
    value: preprocess(
        numberOrNull,
        number({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        }).nonnegative()
    ),
});

export default validation;
