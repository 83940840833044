export enum EMAIL_REQUEST_TYPE {
    PROPERTY_OWNER_TERMINATION_NOTIFICATION = 'PROPERTY_OWNER_TERMINATION_NOTIFICATION',
    RESIDENT_APPROVED_NOTIFICATION = 'RESIDENT_APPROVED_NOTIFICATION',
}

export enum EMAIL_REQUEST_STATUS {
    FAILED = 'FAILED',
    FULFILLED = 'FULFILLED',
    REQUESTED = 'REQUESTED',
}

export type EmailRequest = {
    building_suite_id: string | null;
    created_at: string;
    email_type: EMAIL_REQUEST_TYPE;
    id: string;
    status: EMAIL_REQUEST_STATUS;
    to_user_id: string;
    updated_at: string;
};
