import { zodResolver } from '@hookform/resolvers/zod';
import { Drawer, FormGroup, Select } from '@keyliving/component-lib';
import { SelectableOption } from '@keyliving/shared-types';
import ContractValueInput from 'components/Assets/AssetDetail/Tabs/ContractTab/ContractValueInput';
import { sortContractConditionsAlphabetically } from 'components/Assets/AssetDetail/Tabs/ContractTab/lib';
import SubmitButton from 'components/form/SubmitButton';
import { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useGetAllContractConditionsQuery } from 'redux/modules/contract';
import { output } from 'zod';

import validation from './validation';

export type OfferConditionFormInputs = output<typeof validation>;

interface EditOfferConditionDrawerProps {
    conditionIdToEdit: string | null;
    isOpen: boolean;
    onClose: () => void;
    onEdit: (contract: OfferConditionFormInputs) => void;
}

export default function EditOfferConditionDrawer({
    conditionIdToEdit,
    isOpen,
    onClose,
    onEdit,
}: EditOfferConditionDrawerProps) {
    const { data: contractConditions } = useGetAllContractConditionsQuery();

    const conditionOptions = useMemo<SelectableOption[]>(() => {
        if (!contractConditions) {
            return [];
        }

        return [...contractConditions]
            .sort(sortContractConditionsAlphabetically)
            .map(({ description, id, type }) => {
                let label: string = type;

                if (description !== null) {
                    label = `${description} - ${type}`;
                }

                return {
                    label,
                    value: id,
                };
            });
    }, [contractConditions]);

    const {
        formState: { errors },
        handleSubmit,
        register,
        reset,
        watch,
    } = useForm<OfferConditionFormInputs>({
        resolver: zodResolver(validation), // validation logic depends on existing contracts
    });

    // Must watch in order to correctly display contract value input
    const contractValue = watch('value', 0);
    const selectedCondition = useMemo(
        () =>
            contractConditions?.find((condition) => {
                return condition.id === conditionIdToEdit;
            }),
        [conditionIdToEdit, contractConditions]
    );

    const onSubmit: SubmitHandler<OfferConditionFormInputs> = useCallback(
        async (formData) => {
            onEdit(formData);
            reset();
            onClose();
        },
        [onEdit, onClose, reset]
    );

    return (
        <Drawer onClose={onClose} showDrawer={isOpen} title={'Edit contract'}>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    handleSubmit(onSubmit)(e);
                }}
            >
                <Select
                    controllable={true}
                    disabled={true}
                    label="Contract Condition"
                    name="buildingSuiteContractConditionsId"
                    onChange={() => {}} // empty function as this component doesn't handle changes
                    options={conditionOptions}
                    required
                    value={selectedCondition?.id}
                />
                <ContractValueInput
                    errors={errors}
                    formula={selectedCondition?.formula}
                    label="Value"
                    placeholder="Value"
                    value={contractValue}
                    {...register('value')}
                />
                <FormGroup>
                    <SubmitButton>Edit Contract</SubmitButton>
                </FormGroup>
            </form>
        </Drawer>
    );
}
