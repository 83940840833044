import { zodResolver } from '@hookform/resolvers/zod';
import {
    Button,
    CheckmarkInCircle,
    ContractIcon,
    HouseIcon,
    NoteIcon,
    NumberInput,
    ParagraphIcon,
    PlusInCircleIcon,
    Select,
    Textarea,
    TextInput,
    useToast,
} from '@keyliving/component-lib';
import { Asset, CANADIAN_PROVINCES, COUNTRIES, Offer, OFFER_STATUS } from '@keyliving/shared-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCreateOfferMutation, useUpdateOfferMutation } from 'redux/modules/offer';
import { output } from 'zod';

import { useApprovedHomeBudget } from '../../utils/useApprovedHomeBudget';
import useCreateDefaultContracts from '../../utils/useCreateDefaultContracts';
import useSetupAutoComplete, { AutoCompleteAddressValues } from '../../utils/useSetupAutoComplete';
import AcceptModal from './AcceptModal';
import { AcceptModalInput } from './AcceptModal/AcceptModal';
import AddOfferConditionDrawer from './AddOfferConditionDrawer';
import { OfferConditionFormInputs } from './AddOfferConditionDrawer/AddOfferConditionDrawer';
import DefaultContractConfirmationModal from './DefaultContractConfirmationModal';
import EditOfferConditionDrawer from './EditOfferConditionDrawer';
import FormSectionHeader from './FormSectionHeader';
import OfferContractTable from './OfferContractTable';
import classes from './OfferForm.module.scss';
import validation from './validation';

const ALLOWED_COUNTRIES = {
    CA: COUNTRIES.CA,
};

const ALLOWED_PROVINCES = {
    ON: CANADIAN_PROVINCES.ON,
};

function mapContractsToDraftContracts(contracts: Offer['contracts'] | undefined) {
    if (!contracts) {
        return [];
    }

    return contracts.map((contract) => ({
        buildingSuiteContractConditionsId: contract.building_suite_contract_conditions_id,
        value: contract.value,
    }));
}

interface OfferFormProps {
    asset?: Asset;
    offer?: Offer;
    onSuccessfulSubmit?: () => void;
}

export type OfferFormInputs = output<typeof validation>;

export default function OfferForm({ asset, offer, onSuccessfulSubmit }: OfferFormProps) {
    const toast = useToast();
    const [createOffer, { isLoading: isCreateOfferLoading }] = useCreateOfferMutation();
    const [updateOffer, { isLoading: isUpdateOfferLoading }] = useUpdateOfferMutation();
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const approvedHomeBudget = useApprovedHomeBudget(asset);
    const [addContractDrawerOpen, setAddContractDrawerOpen] = useState(false);
    const [editContractDrawerOpen, setEditContractDrawerOpen] = useState(false);
    const [draftContracts, setDraftContracts] = useState<OfferConditionFormInputs[]>(
        mapContractsToDraftContracts(offer?.contracts)
    );

    const defaultContractsToCreate = useCreateDefaultContracts();
    const [editContractId, setEditContractId] = useState<string | null>(null);
    const autoCompleteInputRef = useRef<HTMLInputElement | null>(null);

    const defaultValues = useMemo(() => {
        if (!offer) {
            return {
                status: OFFER_STATUS.PENDING,
            };
        }
        return {
            status: offer.status,
            listPrice: offer.list_price,
            maxHomeBudget: offer.max_home_budget,
            offerPrice: offer.offer_price,
            buildingStreet: offer.building_street,
            buildingCity: offer.building_city,
            buildingRegion: offer.building_region,
            buildingCountry: offer.building_country,
            buildingPostalCode: offer.building_postal_code,
            notes: offer.notes,
        };
    }, [offer]);

    const {
        formState: { errors },
        getValues,
        handleSubmit,
        register,
        reset,
        trigger,
    } = useForm<OfferFormInputs>({
        resolver: zodResolver(validation),
        defaultValues,
    });
    const currentFormValues = getValues();

    const currentOfferValues = useMemo(() => {
        return {
            ...currentFormValues,
            contracts: draftContracts,
        };
    }, [currentFormValues, draftContracts]);

    const onAutoComplete = useCallback(
        (addressValues: AutoCompleteAddressValues) => {
            const { city, country, postalZip, region, street, streetNumber } = addressValues;

            // Sets the form values based on the resolved address values
            reset({
                ...getValues(),
                buildingUnit: '',
                buildingStreet: `${streetNumber?.long_name} ${street?.long_name}`,
                buildingCity: city?.long_name ?? '',
                buildingRegion: region?.short_name ?? '',
                buildingCountry: country?.short_name ?? '',
                buildingPostalCode: postalZip?.long_name ?? '',
            });

            // Manually triggers form or input validation.
            trigger();
        },
        [reset, trigger, getValues]
    );

    useSetupAutoComplete({ autoCompleteInputRef, onAutoComplete });

    const onDeleteContract = useCallback(
        (id: string) => {
            const indexToRemove = draftContracts.findIndex(
                (contract) => contract.buildingSuiteContractConditionsId === id
            );

            //Create a new array with the item removed at the index
            const newContracts = [
                ...draftContracts.slice(0, indexToRemove),
                ...draftContracts.slice(indexToRemove + 1),
            ];
            setDraftContracts(newContracts);
        },
        [setDraftContracts, draftContracts]
    );

    const onEditContract = useCallback(
        ({ value }: { value: number }) => {
            const indexToEdit = draftContracts.findIndex(
                (contract) => contract.buildingSuiteContractConditionsId === editContractId
            );
            const contractToEdit = draftContracts[indexToEdit];
            contractToEdit.value = value;
            setDraftContracts([
                ...draftContracts.slice(0, indexToEdit),
                contractToEdit,
                ...draftContracts.slice(indexToEdit + 1),
            ]);
            setEditContractId(null);
        },
        [draftContracts, setDraftContracts, editContractId]
    );

    const onClickEditContract = useCallback(
        (id: string) => {
            setEditContractId(id);
            setEditContractDrawerOpen(true);
        },
        [setEditContractDrawerOpen, setEditContractId]
    );

    const onAddContract = useCallback(
        (contract: OfferConditionFormInputs) => {
            setDraftContracts([...draftContracts, contract]);
        },
        [setDraftContracts, draftContracts]
    );

    const addDefaultContracts = () => setShowConfirmationModal(true);

    const confirmDefaultContracts = useCallback(() => {
        const newContracts = defaultContractsToCreate.filter(
            (defaultContract) =>
                !draftContracts.some(
                    (draftContract) =>
                        draftContract.buildingSuiteContractConditionsId ===
                        defaultContract.buildingSuiteContractConditionsId
                )
        );
        setDraftContracts([...draftContracts, ...newContracts]);
        setShowConfirmationModal(false);
    }, [setDraftContracts, draftContracts, defaultContractsToCreate, setShowConfirmationModal]);

    const generateOnSubmitHandler = useCallback(
        (onAcceptedParams?: AcceptModalInput) => {
            const onSubmit: SubmitHandler<OfferFormInputs> = async (formData) => {
                // This is a redundant check as the submit button should be disabled if no asset exists
                if (!asset) {
                    toast.error(
                        'Unable to create or update an offer without an approved application'
                    );
                    return;
                }

                // If the status is submitted, we need to show the accept modal
                if (formData.status === OFFER_STATUS.ACCEPTED && !onAcceptedParams) {
                    setShowAcceptModal(true);
                    return;
                }

                // Create required request body from form and non-form data
                let response;
                if (offer) {
                    const acceptedParams = onAcceptedParams || {};
                    const requestBody = {
                        ...formData,
                        contracts: draftContracts,
                        approvedHomeBudget,
                        ...acceptedParams,
                    };

                    response = await updateOffer({
                        data: requestBody,
                        offerId: offer.id,
                        assetId: asset.id, // This is required for tag invalidation
                    });
                } else {
                    const requestBody = {
                        ...formData,
                        contracts: draftContracts,
                        buildingSuiteId: asset.id,
                        approvedHomeBudget,
                    };

                    response = await createOffer(requestBody);
                }

                if ('error' in response) {
                    toast.error(response.error.message || 'Something went wrong');
                }

                if ('data' in response) {
                    if (onSuccessfulSubmit) {
                        onSuccessfulSubmit();
                    }
                    toast.success('Offer submitted successfully');
                }
            };

            return onSubmit;
        },
        [
            asset,
            updateOffer,
            approvedHomeBudget,
            createOffer,
            toast,
            draftContracts,
            offer,
            onSuccessfulSubmit,
        ]
    );

    const handleAcceptOffer = useCallback(
        (onAcceptedParams: AcceptModalInput) => {
            handleSubmit(generateOnSubmitHandler(onAcceptedParams))();
        },
        [handleSubmit, generateOnSubmitHandler]
    );

    const isDisabled = !asset;
    const isLoading = isCreateOfferLoading || isUpdateOfferLoading;

    return (
        <form>
            <FormSectionHeader headerIcon={ParagraphIcon} text="Details" />
            <div className={classes['split-input-container']}>
                <span>
                    <NumberInput
                        disabled={true}
                        label="Approved applicant budget"
                        name="homeBudget"
                        prefix="$"
                        value={approvedHomeBudget}
                    />
                </span>
                <span>
                    <NumberInput
                        errors={errors}
                        label="List Price"
                        {...register('listPrice')}
                        clearable={true}
                        disabled={isDisabled}
                        placeholder={'List Price'}
                        prefix="$"
                        required
                        step=".01"
                    />
                </span>
            </div>
            <div className={classes['split-input-container']}>
                <span>
                    <NumberInput
                        errors={errors}
                        label="Max Home Budget"
                        {...register('maxHomeBudget')}
                        clearable={true}
                        disabled={isDisabled}
                        prefix="$"
                        step=".01"
                    />
                </span>
                <span>
                    <NumberInput
                        errors={errors}
                        label="Offer Price"
                        {...register('offerPrice')}
                        clearable={true}
                        disabled={isDisabled}
                        prefix="$"
                        required
                        step=".01"
                    />
                </span>
            </div>
            <FormSectionHeader headerIcon={HouseIcon} text="Address" />

            <div className={classes['address-search-container']}>
                <TextInput
                    label="Search for address"
                    name="addressSearch" // Not part of form data
                    placeholder="Search address"
                    ref={autoCompleteInputRef}
                />
            </div>
            <div className={classes['split-input-container']}>
                <span>
                    <NumberInput
                        errors={errors}
                        label="Unit"
                        {...register('buildingUnit')}
                        clearable={true}
                        disabled={isDisabled}
                    />
                </span>
                <span>
                    <TextInput
                        errors={errors}
                        label="Street address"
                        {...register('buildingStreet')}
                        clearable={true}
                        disabled={isDisabled}
                        required
                        step=".01"
                    />
                </span>
            </div>
            <div className={classes['split-input-container']}>
                <span>
                    <TextInput
                        errors={errors}
                        label="City"
                        {...register('buildingCity')}
                        clearable={true}
                        disabled={isDisabled}
                        required
                        step=".01"
                    />
                </span>
                <span>
                    <Select
                        errors={errors}
                        label="Province/Territory"
                        {...register('buildingRegion')}
                        disabled={isDisabled}
                        options={Object.entries(ALLOWED_PROVINCES).map(([value, label]) => ({
                            label,
                            value,
                        }))}
                        required
                    />
                </span>
            </div>
            <div className={classes['split-input-container']}>
                <span>
                    <Select
                        errors={errors}
                        label="Country"
                        {...register('buildingCountry')}
                        disabled={isDisabled}
                        options={Object.entries(ALLOWED_COUNTRIES).map(([value, label]) => ({
                            label,
                            value,
                        }))}
                        required
                    />
                </span>
                <span>
                    <TextInput
                        errors={errors}
                        label="Postal Code"
                        {...register('buildingPostalCode')}
                        clearable={true}
                        disabled={isDisabled}
                        required
                        step=".01"
                    />
                </span>
            </div>
            <FormSectionHeader headerIcon={ContractIcon} text="Contracts" />
            <OfferContractTable
                contracts={draftContracts}
                onDelete={onDeleteContract}
                onEdit={onClickEditContract}
            />
            <AddOfferConditionDrawer
                contracts={draftContracts}
                isOpen={addContractDrawerOpen}
                onAdd={onAddContract}
                onClose={() => setAddContractDrawerOpen(false)}
            />
            <EditOfferConditionDrawer
                conditionIdToEdit={editContractId}
                isOpen={editContractDrawerOpen}
                onClose={() => setEditContractDrawerOpen(false)}
                onEdit={onEditContract}
            />
            <DefaultContractConfirmationModal
                onCancel={() => setShowConfirmationModal(false)}
                onConfirm={confirmDefaultContracts}
                showModal={showConfirmationModal}
            />
            <div className={classes['contract-button-container']}>
                <Button
                    disabled={isDisabled}
                    fill="transparent"
                    onClick={() => setAddContractDrawerOpen(true)}
                    size="sm"
                    theme="tertiary"
                    type="button"
                >
                    <PlusInCircleIcon height={20} width={20} />
                    Add contract
                </Button>
                <Button
                    disabled={isDisabled}
                    fill="transparent"
                    onClick={addDefaultContracts}
                    size="sm"
                    theme="tertiary"
                    type="button"
                >
                    <PlusInCircleIcon height={20} width={20} />
                    Add default contracts
                </Button>
            </div>
            <FormSectionHeader headerIcon={NoteIcon} text="Additional details" />
            <Textarea
                errors={errors}
                label="Notes"
                {...register('notes')}
                disabled={isDisabled}
                placeholder="Notes..."
            />
            <FormSectionHeader headerIcon={CheckmarkInCircle} text="Status" />
            <Select
                errors={errors}
                label="Status"
                {...register('status')}
                disabled={isDisabled || !offer} // Status must be PENDING when offer is created
                options={Object.entries(OFFER_STATUS).map(([value, label]) => ({
                    label,
                    value,
                }))}
                required
            />
            <div className={classes['update-buttons']}>
                <div className={classes['submit-offer-button']}>
                    {offer ? (
                        <Button
                            disabled={isDisabled}
                            isLoading={isLoading}
                            onClick={handleSubmit(generateOnSubmitHandler())}
                            size="sm"
                        >
                            Update Offer
                        </Button>
                    ) : (
                        <Button
                            disabled={isDisabled}
                            isLoading={isLoading}
                            onClick={handleSubmit(generateOnSubmitHandler())}
                            size="sm"
                        >
                            Create Offer
                        </Button>
                    )}
                </div>
                {offer && (
                    <AcceptModal
                        closeModal={() => setShowAcceptModal(false)}
                        draftOffer={currentOfferValues}
                        offer={offer}
                        onAccept={handleAcceptOffer}
                        showModal={showAcceptModal}
                    />
                )}
            </div>
        </form>
    );
}
