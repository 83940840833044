import { ConfigurableTable } from '@keyliving/component-lib';
import { OraField } from '@keyliving/shared-types';
import { toMoney } from '@keyliving/utils';
import { useMemo } from 'react';
import { Column } from 'react-table';

type ORAFieldType = 'text' | 'money' | 'percentage';

const fieldIdsToNameMap: Record<string, { name: string; type: ORAFieldType }> = {
    address: { name: 'Address', type: 'text' },
    ownerResidentNames: { name: 'Owner Resident Names', type: 'text' },
    agreementStartDate: { name: 'Agreement Start Date', type: 'text' },
    agreementEndDate: { name: 'Agreement End Date', type: 'text' },
    purchasePrice: { name: 'Purchase Price', type: 'money' },
    annualEquityAppreciationRate: { name: 'Annual Equity Appreciation Rate', type: 'percentage' },
    estimatedFinalPurchasePrice: { name: 'Estimated EOT Purchase Price', type: 'money' },
    downPayment: { name: 'Down Payment', type: 'money' },
    proRataResidencyPayment: { name: 'Pro Rata Residency Payment', type: 'money' },
    equityAdminFee: { name: 'Equity Admin Fee', type: 'money' },
    totalInitialCost: { name: 'Total Initial Cost', type: 'money' },
    deposit: { name: 'Monthly Equity Deposit', type: 'money' },
    equityAdminFeeMonthly: { name: 'Equity Admin Fee Monthly', type: 'money' },
    occupancyFee: { name: 'Monthly Occupancy Fee', type: 'money' },
    recurringServices: { name: 'Recurring Services', type: 'money' },
    monthlyResidencyPayment: { name: 'Monthly Residency Payment', type: 'money' },
};

interface OraPreviewTableProps {
    oraFields: OraField[];
}

export default function OraPreviewTable({ oraFields }: OraPreviewTableProps) {
    const filteredOraFields = useMemo(() => {
        return oraFields
            .filter((field) => Boolean(fieldIdsToNameMap[field.api_id]))
            .sort((a, b) => {
                const aIndex = Object.keys(fieldIdsToNameMap).indexOf(a.api_id);
                const bIndex = Object.keys(fieldIdsToNameMap).indexOf(b.api_id);
                return aIndex - bIndex;
            });
    }, [oraFields]);

    const columns = useMemo<Column<OraField>[]>(
        () => [
            {
                Header: () => 'Field Name', // To ensure this column can't be hidden
                id: 'name',
                accessor: (row) => fieldIdsToNameMap[row.api_id].name,
            },
            {
                Header: 'Field Value',
                id: 'value',
                accessor: (row) => {
                    switch (fieldIdsToNameMap[row.api_id].type) {
                        case 'text':
                            return row.value;
                        case 'money':
                            return toMoney(Number(row.value));
                        case 'percentage':
                            return `${row.value}%`;
                        default:
                            return '';
                    }
                },
            },
        ],
        []
    );

    return (
        <ConfigurableTable<OraField> columns={columns} data={filteredOraFields} rowsPerPage={50} />
    );
}
