import { CONTRACT_CONDITION_TYPE } from '@keyliving/shared-types';
import { useGetAllContractConditionsQuery } from 'redux/modules/contract';

export default function useCreateDefaultContracts() {
    const { data: contractConditions } = useGetAllContractConditionsQuery();

    const occupancyTerm = contractConditions?.find((condition) => {
        return condition.type === CONTRACT_CONDITION_TYPE.OCCUPANCY_TERM;
    });
    const autoEquityPurchase = contractConditions?.find((condition) => {
        return condition.type === CONTRACT_CONDITION_TYPE.AUTO_EQUITY_PURCHASE;
    });
    const downPaymentPercentage = contractConditions?.find((condition) => {
        return condition.type === CONTRACT_CONDITION_TYPE.DOWNPAYMENT_PERCENTAGE;
    });
    const occupancyPayment = contractConditions?.find((condition) => {
        return condition.type === CONTRACT_CONDITION_TYPE.OCCUPANCY_PAYMENT;
    });
    const equityGrowthAnnualEffective = contractConditions?.find((condition) => {
        return condition.type === CONTRACT_CONDITION_TYPE.EQUITY_GROWTH_ANNUAL_EFFECTIVE;
    });
    const salesTax = contractConditions?.find((condition) => {
        return condition.type === CONTRACT_CONDITION_TYPE.SALES_TAX;
    });
    const residentOnboardingFee = contractConditions?.find((condition) => {
        return condition.type === CONTRACT_CONDITION_TYPE.RESIDENT_ONBOARDING_FEE;
    });
    const fixedGrowthValuationMethod = contractConditions?.find((condition) => {
        return condition.type === CONTRACT_CONDITION_TYPE.FIXED_GROWTH_VALUATION_METHOD;
    });

    const defaultContracts = [];

    if (occupancyTerm) {
        defaultContracts.push({
            buildingSuiteContractConditionsId: occupancyTerm.id,
            value: 5,
        });
    }

    if (autoEquityPurchase) {
        defaultContracts.push({
            buildingSuiteContractConditionsId: autoEquityPurchase.id,
            value: 200,
        });
    }

    if (downPaymentPercentage) {
        defaultContracts.push({
            buildingSuiteContractConditionsId: downPaymentPercentage.id,
            value: 0.05,
        });
    }

    if (occupancyPayment) {
        defaultContracts.push({
            buildingSuiteContractConditionsId: occupancyPayment.id,
            value: 3800,
        });
    }

    if (equityGrowthAnnualEffective) {
        defaultContracts.push({
            buildingSuiteContractConditionsId: equityGrowthAnnualEffective.id,
            value: 0.05,
        });
    }

    if (salesTax) {
        defaultContracts.push({
            buildingSuiteContractConditionsId: salesTax.id,
            value: 0.13,
        });
    }

    if (residentOnboardingFee) {
        defaultContracts.push({
            buildingSuiteContractConditionsId: residentOnboardingFee.id,
            value: 500,
        });
    }

    if (fixedGrowthValuationMethod) {
        defaultContracts.push({
            buildingSuiteContractConditionsId: fixedGrowthValuationMethod.id,
            value: 0.125,
        });
    }

    return defaultContracts;
}
