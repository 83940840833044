import classes from './FormSectionHeader.module.scss';

interface FormSectionHeaderProps {
    headerIcon: React.ComponentType<{ height: number; width: number }>;
    text: string;
}

export default function FormSectionHeader({
    headerIcon: HeaderIcon,
    text,
}: FormSectionHeaderProps) {
    return (
        <div className={classes['header-container']}>
            <div className={classes['icon-container']}>
                <HeaderIcon height={18} width={18} />
            </div>
            <div className={classes['text-container']}>{text}</div>
            <div className={classes['dashed-line']} />
        </div>
    );
}
