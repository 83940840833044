import { numberOrNull, stringOrNull } from 'utils/validation';
import { number, object, preprocess, string } from 'zod';

import { REQUIRED_MSG } from '../../../../../../../../constants';

const validation = object({
    acceptedDate: preprocess(
        stringOrNull,
        string({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        })
    ),
    agreementStartDate: preprocess(
        stringOrNull,
        string({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        })
    ),
    finalPurchasePrice: preprocess(
        numberOrNull,
        number({
            required_error: REQUIRED_MSG,
            invalid_type_error: REQUIRED_MSG,
        }).positive()
    ),
});

export default validation;
