import { Offer } from '@keyliving/shared-types';

export default function useDefaultAcceptValues(offer: Offer) {
    const agreementStartDate = offer.agreement_start_date
        ? new Date().toISOString().split('T')[0]
        : '';

    const acceptedDate = offer.accepted_date
        ? new Date(offer.accepted_date).toISOString().split('T')[0]
        : '';

    const finalPurchasePrice = offer.final_purchase_price || undefined;

    return {
        agreementStartDate,
        acceptedDate,
        finalPurchasePrice,
    };
}
