import { zodResolver } from '@hookform/resolvers/zod';
import {
    DateInput,
    InfoIcon,
    LoadingRing,
    Modal,
    NumberInput,
    useToast,
} from '@keyliving/component-lib';
import { Offer } from '@keyliving/shared-types';
import SubmitButton from 'components/form/SubmitButton';
import { useDebounce } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useGetOraPreviewForOfferMutation } from 'redux/modules/offer';
import { getFormattedAddress } from 'utils/offer';
import { output } from 'zod';

import useDefaultAcceptValues from '../../../utils/useDefaultAcceptValues';
import { OfferConditionFormInputs } from '../AddOfferConditionDrawer/AddOfferConditionDrawer';
import { OfferFormInputs } from '../OfferForm';
import classes from './AcceptModal.module.scss';
import OraPreviewTable from './OraPreviewTable';
import validation from './validation';

export type AcceptModalInput = output<typeof validation>;

export type AcceptModalOffer = OfferFormInputs & {
    contracts: OfferConditionFormInputs[];
};
interface AcceptModalProps {
    closeModal: () => void;
    draftOffer: AcceptModalOffer;
    offer: Offer;
    onAccept: (onAcceptedParams: AcceptModalInput) => void;
    showModal: boolean;
}

export default function AcceptModal({
    closeModal,
    draftOffer,
    offer,
    onAccept,
    showModal,
}: AcceptModalProps) {
    const toast = useToast();
    const [getOraPreviewForOffer, { data: oraFields, isLoading }] =
        useGetOraPreviewForOfferMutation();

    const defaultValues = useDefaultAcceptValues(offer);
    const formattedAddress = getFormattedAddress({
        building_city: draftOffer.buildingCity,
        building_postal_code: draftOffer.buildingPostalCode,
        building_region: draftOffer.buildingRegion,
        building_street: draftOffer.buildingStreet,
        building_unit: draftOffer.buildingUnit,
    });

    const {
        formState: { errors },
        getValues,
        handleSubmit,
        register,
    } = useForm<AcceptModalInput>({
        resolver: zodResolver(validation),
        defaultValues,
    });

    const onInputChange = useDebounce(async () => {
        try {
            await getOraPreviewForOffer({
                finalPurchasePrice: Number(getValues('finalPurchasePrice')),
                agreementStartDate: getValues('agreementStartDate'),
                buildingUnit: draftOffer.buildingUnit || undefined,
                buildingStreet: draftOffer.buildingStreet,
                buildingCity: draftOffer.buildingCity,
                buildingRegion: draftOffer.buildingRegion,
                buildingPostalCode: draftOffer.buildingPostalCode,
                contracts: draftOffer.contracts,
                buildingSuiteId: offer.building_suite_id,
            }).unwrap();
        } catch (error) {
            toast.error('Failed to get ORA preview values');
        }
    });

    const onSubmit = useCallback(
        (formData: AcceptModalInput) => {
            onAccept(formData);
            closeModal();
        },
        [onAccept, closeModal]
    );

    useEffect(() => {
        const finalPurchasePrice = Number(getValues('finalPurchasePrice'));
        const agreementStartDate = getValues('agreementStartDate');

        // Only fetch ora preview when modal opens, otherwise many requests are made
        if (showModal && finalPurchasePrice && agreementStartDate) {
            getOraPreviewForOffer({
                finalPurchasePrice,
                agreementStartDate,
                buildingUnit: draftOffer.buildingUnit || undefined,
                buildingStreet: draftOffer.buildingStreet,
                buildingCity: draftOffer.buildingCity,
                buildingRegion: draftOffer.buildingRegion,
                buildingPostalCode: draftOffer.buildingPostalCode,
                contracts: draftOffer.contracts,
                buildingSuiteId: offer.building_suite_id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    return (
        <Modal className={classes['modal-classes']} onClose={closeModal} showModal={showModal}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.wrapper}>
                    <div className={classes['title-wrapper']}>
                        Approve offer for {formattedAddress}
                    </div>
                    <div className={classes['input-wrapper']}>
                        <DateInput
                            errors={errors}
                            label="Agreement Start Date"
                            required
                            {...register('agreementStartDate', {
                                onChange: onInputChange,
                            })}
                        />
                        <div className={classes['fields-wrapper']}>
                            <span>
                                <DateInput
                                    errors={errors}
                                    label="Date Accepted"
                                    required
                                    {...register('acceptedDate')}
                                />
                            </span>
                            <span>
                                <NumberInput
                                    errors={errors}
                                    label="Purchase Price"
                                    {...register('finalPurchasePrice', {
                                        onChange: onInputChange,
                                    })}
                                    placeholder={'Purchase Price'}
                                    prefix="$"
                                    required
                                    step=".01"
                                />
                            </span>
                        </div>
                        {draftOffer.maxHomeBudget && (
                            <div className={classes['info-container']}>
                                <InfoIcon height={14} width={14} />
                                Property approved for a max home budget of{' '}
                                <div className={classes['bold-number']}>
                                    ${draftOffer.maxHomeBudget}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes['table-container']}>
                        <div className={classes['table-name']}>ORA Value Preview</div>
                        {isLoading && <LoadingRing />}
                        {oraFields && !isLoading && <OraPreviewTable oraFields={oraFields} />}
                    </div>
                    <div className={classes['submit-button']}>
                        <SubmitButton>Accept offer</SubmitButton>
                    </div>
                </div>
            </form>
        </Modal>
    );
}
