export type FundingAccount = {
    id: string;
    user_id: string;
    account_info: string;
    created_at: string;
    updated_at: string;
};

export enum INSTITUION_SHORT_NAME {
    RBC = 'RBC',
    TD = 'TDBank',
    BMO = 'BMO',
    CIBC = 'CIBC',
    SCOTIA = 'ScotiaBank',
    TANGERINE = 'Tangerine',
    DESJARDINS = 'Desjardins',
    NBC = 'NBC',
    HSBC = 'HSBC',
    LAURENTIAN = 'Laurentian',
    WS = 'Wealthsimple',
    ATB = 'ATB',
}

export type CensoredFundingAccount = {
    account_number: string;
    institution_name: string;
    institution_number: string;
    institution_short_name: INSTITUION_SHORT_NAME;
    created_at: string | null;
    updated_at: string | null;
};

export type FundingAccountInfo = {
    institutionNumber: string;
    transitNumber: string;
    accountNumber: string;
    street: string;
    city: string;
    province: string;
    postalCode: string;
    country: string;
    firstName: string;
    lastName: string;
};
