import { Offer } from '@keyliving/shared-types';

export function getFormattedAddress(params: {
    building_city: string;
    building_postal_code: string;
    building_region: string;
    building_street: string;
    building_unit: string | undefined | null;
}) {
    const { building_city, building_postal_code, building_region, building_street, building_unit } =
        params;
    const sections: string[] = [];

    if (building_unit) {
        sections.push(`${building_unit} - `);
    }

    sections.push(
        `${building_street} ${building_city}, ${building_region} ${building_postal_code}`
    );

    return sections.join('');
}

export function getFormattedAddressFromOffer(offer: Offer | undefined | null): string | undefined {
    if (!offer) {
        return undefined;
    }

    return getFormattedAddress(offer);
}
