import { zodResolver } from '@hookform/resolvers/zod';
import { Drawer, Select } from '@keyliving/component-lib';
import ContractValueInput from 'components/Assets/AssetDetail/Tabs/ContractTab/ContractValueInput';
import { sortContractConditionsAlphabetically } from 'components/Assets/AssetDetail/Tabs/ContractTab/lib';
import SubmitButton from 'components/form/SubmitButton';
import { useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useGetAllContractConditionsQuery } from 'redux/modules/contract';
import { infer as zodInfer } from 'zod';

import classes from './AddOfferConditionDrawer.module.scss';
import getValidation from './validation';

export type OfferConditionFormInputs = zodInfer<ReturnType<typeof getValidation>>;

interface AddOfferConditionDrawerProps {
    contracts: OfferConditionFormInputs[];
    isOpen: boolean;
    onAdd: (contract: OfferConditionFormInputs) => void;
    onClose: () => void;
}

export default function AddOfferConditionDrawer({
    contracts,
    isOpen,
    onAdd,
    onClose,
}: AddOfferConditionDrawerProps) {
    const { data: contractConditions } = useGetAllContractConditionsQuery();

    const conditionOptions = useMemo(() => {
        if (!contractConditions) {
            return [];
        }

        return [...contractConditions]
            .sort(sortContractConditionsAlphabetically)
            .map(({ description, id, type }) => {
                let label: string = type;

                if (description !== null) {
                    label = `${description} - ${type}`;
                }

                return {
                    label,
                    value: id,
                };
            });
    }, [contractConditions]);

    const {
        formState: { errors },
        handleSubmit,
        register,
        reset,
        watch,
    } = useForm<OfferConditionFormInputs>({
        resolver: zodResolver(getValidation(contracts)), // validation logic depends on existing contracts
    });

    // Must watch in order to correctly display contract value input
    const contractValue = watch('value', 0);
    const contractConditionId = watch('buildingSuiteContractConditionsId');
    const selectedCondition = contractConditions?.find((condition) => {
        return condition.id === contractConditionId;
    });

    const onSubmit: SubmitHandler<OfferConditionFormInputs> = useCallback(
        async (formData) => {
            onAdd(formData);
            reset();
            onClose();
        },
        [onAdd, onClose, reset]
    );

    return (
        <Drawer onClose={onClose} showDrawer={isOpen} title={'Add contract'}>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    handleSubmit(onSubmit)(e);
                }}
            >
                <Select
                    defaultEmpty
                    errors={errors}
                    label="Contract Condition"
                    {...register('buildingSuiteContractConditionsId')}
                    options={conditionOptions}
                    required
                />
                <ContractValueInput
                    errors={errors}
                    formula={selectedCondition?.formula}
                    label="Value"
                    placeholder="Value"
                    value={contractValue}
                    {...register('value')}
                />
                <div className={classes['add-contract-button']}>
                    <SubmitButton>Add Contract</SubmitButton>
                </div>
            </form>
        </Drawer>
    );
}
