import { Button, Modal } from '@keyliving/component-lib';

import classes from './DefaultContractConfirmationModal.module.scss';

interface DefaultContractConfirmationModalProps {
    onCancel: () => void;
    onConfirm: () => void;
    showModal: boolean;
}

export default function DefaultContractConfirmationModal({
    onCancel,
    onConfirm,
    showModal,
}: DefaultContractConfirmationModalProps) {
    return (
        <Modal onClose={onCancel} showModal={showModal}>
            <h1 className={classes['modal-title']}>Confirm your choice</h1>
            <p className={classes['modal-text']}>
                Confirm your choice to add default contracts. Note that adding default contracts
                will not overwrite existing contracts and that contracts created this way may need
                to have their values edited.
            </p>
            <div className={classes['modal-buttons']}>
                <Button fill="outline" onClick={onCancel} rounded type="button">
                    Cancel
                </Button>
                <Button onClick={onConfirm} rounded type="button">
                    Add contracts
                </Button>
            </div>
        </Modal>
    );
}
