import { Badge, BadgeSize } from '@keyliving/component-lib';
import { OFFER_STATUS } from '@keyliving/shared-types';
import classNames from 'classnames/bind';
import { useMemo } from 'react';

import classes from './OfferStatusBadge.module.scss';

const cx = classNames.bind(classes);

interface OfferBadgeProps {
    size: BadgeSize;
    status: OFFER_STATUS | undefined;
}

export default function OfferBadge(
    { size, status }: OfferBadgeProps = { status: OFFER_STATUS.PENDING, size: 'sm' }
) {
    const offerStatus = useMemo(() => {
        switch (status) {
            case OFFER_STATUS.ACCEPTED:
                return 'Accepted';
            case OFFER_STATUS.REJECTED:
                return 'Rejected';
            case OFFER_STATUS.NOT_PROCEEDING:
                return 'Not proceeding';
            default:
                return 'Conditional';
        }
    }, [status]);

    return (
        <Badge
            className={cx(classes['offer-badge'], {
                [classes['offer-badge--accepted']]: status === OFFER_STATUS.ACCEPTED,
                [classes['offer-badge--rejected']]: status === OFFER_STATUS.REJECTED,
                [classes['offer-badge--not_proceeding']]: status === OFFER_STATUS.NOT_PROCEEDING,
            })}
            element="div"
            label={offerStatus}
            size={size}
        />
    );
}
